exports.components = {
  "component---src-components-templates-article-show-jsx": () => import("./../../../src/components/templates/article-show.jsx" /* webpackChunkName: "component---src-components-templates-article-show-jsx" */),
  "component---src-components-templates-expertise-show-jsx": () => import("./../../../src/components/templates/expertise-show.jsx" /* webpackChunkName: "component---src-components-templates-expertise-show-jsx" */),
  "component---src-components-templates-project-jsx": () => import("./../../../src/components/templates/project.jsx" /* webpackChunkName: "component---src-components-templates-project-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-en-js": () => import("./../../../src/pages/contacts.en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-contatti-it-js": () => import("./../../../src/pages/contatti.it.js" /* webpackChunkName: "component---src-pages-contatti-it-js" */),
  "component---src-pages-expertise-en-js": () => import("./../../../src/pages/expertise.en.js" /* webpackChunkName: "component---src-pages-expertise-en-js" */),
  "component---src-pages-expertise-it-js": () => import("./../../../src/pages/expertise.it.js" /* webpackChunkName: "component---src-pages-expertise-it-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-notizie-it-js": () => import("./../../../src/pages/notizie.it.js" /* webpackChunkName: "component---src-pages-notizie-it-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-it-js": () => import("./../../../src/pages/privacy-policy.it.js" /* webpackChunkName: "component---src-pages-privacy-policy-it-js" */),
  "component---src-pages-progetti-it-js": () => import("./../../../src/pages/progetti.it.js" /* webpackChunkName: "component---src-pages-progetti-it-js" */),
  "component---src-pages-projects-en-js": () => import("./../../../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-studio-en-js": () => import("./../../../src/pages/studio.en.js" /* webpackChunkName: "component---src-pages-studio-en-js" */),
  "component---src-pages-studio-it-js": () => import("./../../../src/pages/studio.it.js" /* webpackChunkName: "component---src-pages-studio-it-js" */),
  "component---src-pages-team-en-js": () => import("./../../../src/pages/team.en.js" /* webpackChunkName: "component---src-pages-team-en-js" */),
  "component---src-pages-team-it-js": () => import("./../../../src/pages/team.it.js" /* webpackChunkName: "component---src-pages-team-it-js" */),
  "component---src-pages-wau-camp-form-test-it-js": () => import("./../../../src/pages/wau-camp-form-test.it.js" /* webpackChunkName: "component---src-pages-wau-camp-form-test-it-js" */),
  "component---src-pages-wau-camp-it-js": () => import("./../../../src/pages/wau-camp.it.js" /* webpackChunkName: "component---src-pages-wau-camp-it-js" */)
}

